import React, { useState, useEffect, useMemo } from 'react';
import { useHistory } from 'react-router-dom';
import ErpClienteTemplate from '../../../components/ErpClienteTemplate';
import NavBarCliente from '../../../components/NavBarCliente';
import ErpTemplate from '../../../components/ErpTemplate';
import { FaShuttleVan, FaSearch, FaTable, FaIdCard, FaEdit } from 'react-icons/fa';
import { MdHotel } from "react-icons/md";
import { ImAirplane } from "react-icons/im";
import { BsCardChecklist } from 'react-icons/bs';
import { GrMail } from "react-icons/gr";
import { ImCancelCircle } from 'react-icons/im';
import { GiOpenChest } from 'react-icons/gi';
import NavItem from '../../../components/NavItem';
import IconButton from '../../../components/IconButton';
import api from '../../../services/api';
import DataTable from 'react-data-table-component';
import moment from 'moment';
import { dataMysql } from '../../../util/util';
import Select from 'react-select';
import { useLocation } from 'react-router-dom';
import ReactSelect from '../../../components/ReactSelect';
import { toast } from 'react-toastify';
import FormButton from '../../../components/FormButton';
import DadosEventoParticipante from '../../../components/DadosEventoParticipante';
import { data, dataHora } from '../../../util/util';

export default function PainelEventoParticipante({ match }) {
    let { search, state } = useLocation();
    const id = match.params.id;
    const token = localStorage.getItem('token');
    const perfil = localStorage.getItem("perfil");
    const [filtro, setFiltro] = useState({});
    const [eventoParticipantes, setEventoParticipantes] = useState([]);
    const [evento, setEvento] = useState([]);
    const [tableSelect, setTableSelect] = useState({});
    const [tableActions, setTableActions] = useState([]);
    const operador = state?.operador ?? null;
    let timerBuscaCliente;

    const atualizarGrupo = novoValor => {
        setFiltro({ ...filtro, grupoSituacaoEvento: novoValor });
    };

    const buscar = () => {
        setFiltro({ ...filtro, ...{ buscando: true } });
        //busca dados do evento
        api({
            method: 'get',
            action: '/evento',
            token: token,
            params: {
                ...{ id: id }
            }
        })
            .then(response => {
                var evento = response.data.data[0];
                setEvento(evento);
                setFiltro({ ...filtro, ...{ buscando: false } });
            }).catch(err => {
                //console.log(err)
            });

        //busca participantes do evento
        api({
            method: 'get',
            action: '/participante',
            token: token,
            params: {
                ...filtro,
                ...{
                    evento: id,
                    tipoCadastro: "participante",
                }
            }
        })
            .then(response => {
                setEventoParticipantes(response.data.data);
            }).catch(err => {
                //console.log(err)
            });
    };

    useEffect(() => {
        buscar();
    },
        // eslint-disable-next-line
        [filtro.grupoSituacaoEvento]);

    return (
        <ErpClienteTemplate>
            <main className="main-content position-relative border-radius-lg ">
                <NavBarCliente
                    titulo={evento.nomersvp ?? evento.nome ?? ""}
                    menu={[
                        <a
                            className="nav-link text-white font-weight-bold px-0"
                            href={"/eventoRelatorioParticipante/" + (evento.id ?? "")}
                        >
                            <span className="d-sm-inline"><FaTable /> Relatório de Participantes</span>
                        </a>
                    ]} />
                <div className="container-fluid">
                    <DadosEventoParticipante idEvento={evento.id ?? null} atualizarGrupo={atualizarGrupo} />
                    <div className="row mt-2">
                        <div className="col-lg-12 mb-lg-0 mb-4">
                            <div className="card">
                                <div className="card-header pb-0">
                                    <form className="form" onSubmit={(e) => { buscar(); e.preventDefault(); }}>
                                        <div className="row">
                                            <div className="col-sm-12">
                                                <div className="form-floating form-group">
                                                    <input type="text" className="form-control form-control-lg" id="nome" placeholder="Buscar pelo nome do participante"
                                                        onChange={(e) => {
                                                            filtro.nome = e.target.value;
                                                            setFiltro({ ...filtro });
                                                        }}
                                                    />
                                                    <label htmlFor="floatingInput">Nome do Participante</label>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-sm-12 d-flex justify-content-end">
                                                <button type="submit" className="btn btn-primary me-1 mb-1">
                                                    <FaSearch size={20} /> Buscar
                                                </button>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                                <div className="card-body px-0 pt-0 pb-2">
                                    <div className="table-responsive">
                                        <DataTable
                                            progressPending={filtro.buscando ?? false}
                                            progressComponent={<div>Buscando...</div>}
                                            noContextMenu="true"
                                            noDataComponent={"Nenhum registro"}
                                            paginationComponentOptions={{ rowsPerPageText: 'Linhas', rangeSeparatorText: 'de', selectAllRowsItem: true, selectAllRowsItemText: 'Todos' }}
                                            pagination="true"
                                            className="table mb-0"
                                            columns={[
                                                {
                                                    name: '',
                                                    width: "200px",
                                                    cell: (row) => {
                                                        return <IconButton
                                                            style={{ marginBottom: "0px" }}
                                                            small={true}
                                                            to={"/eventoParticipanteDetalhes/" + row.id}
                                                            icon={<FaIdCard size={20} />}
                                                            color="primary">Detalhes</IconButton>;
                                                    }
                                                },
                                                {
                                                    name: 'Nome',
                                                    selector: row => row.nome ?? "",
                                                    sortable: true,
                                                    cell: (row) => {
                                                        return <div>{row.nome}</div>
                                                    },
                                                },
                                                {
                                                    name: 'Tipo',
                                                    width: "130px",
                                                    selector: row => row.tipoparticipante?.nome ?? "",
                                                    sortable: true,
                                                    cell: (row) => {
                                                        return <div>{row.tipoparticipante?.nome ?? ""}</div>
                                                    },
                                                },
                                                {
                                                    name: 'Patrocínios',
                                                    width: "200px",
                                                    cell: (row) => {
                                                        let icones = [];
                                                        if ((row.hotelhospedagem ?? "nao") === "sim") {
                                                            icones.push(<MdHotel title='Hotel' size={20} style={{ marginRight: "5px", color: "#FF9955" }} />);
                                                        }
                                                        if ((row.aereo ?? "nao") === "sim") {
                                                            icones.push(<ImAirplane title='Aéreo' size={20} style={{ marginRight: "5px", color: "#5599FF" }} />);
                                                        }
                                                        if ((row.inscricao ?? "nao") === "sim") {
                                                            icones.push(<FaEdit title='Inscrição' size={20} style={{ marginRight: "5px", color: "#FF7777" }} />);
                                                        }
                                                        if (((row.transfer ?? "nao") === "sim") || ((row.transferextra ?? "nao") === "sim")) {
                                                            icones.push(<FaShuttleVan title='Transfer' size={20} style={{ marginRight: "5px", color: "#55DD55" }} />);
                                                        }
                                                        return <div>{icones.map(item => item)}</div>;
                                                    },
                                                },
                                                {
                                                    name: 'Status',
                                                    selector: row => row.situacaoevento?.nome ?? "",
                                                    sortable: true,
                                                    width: "200px",
                                                    cell: (row) => {
                                                        return row.situacaoevento ?
                                                            <span className="badge" style={{ color: "#000000", fontSize: "8px", backgroundColor: row.situacaoevento.cor }} >{row.situacaoevento.nome}</span> : "";
                                                    },
                                                },
                                            ]}
                                            data={eventoParticipantes}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </main>
        </ErpClienteTemplate>
    );
}