import React, { useState, useEffect } from 'react';
import ErpClienteTemplate from '../../../components/ErpClienteTemplate';
import api from '../../../services/api';
import ReactSelect from '../../../components/ReactSelect';
import { FaPaperPlane, FaSave } from 'react-icons/fa';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
import { useLocation } from 'react-router-dom';

export default function CadastroEventoPergunta({ match }) {
    let { search, state } = useLocation();
    const id = match.params.id;
    const [dadosEvento, setDadosEvento] = useState({});
    const [dados, setDados] = useState({
        evento: id,
    });
    const [isSubmitting, setIsSubmitting] = useState(false);
    const history = useHistory();

    const buscar = () => {
        //busca evento
        api({
            method: 'get',
            action: '/eventoSemLogin/' + id,
        })
            .then(response => {
                setDadosEvento(response.data.data);
            }).catch(err => {
                //console.log(err)
            });
    };

    const validar = () => {
        var palestrante = document.querySelector("#palestrante");
        if ((dados.palestrante ?? '') === '') {
            toast.error('É necessário informar o palestrante');
            setTimeout(() => {
                palestrante.focus();
            }, 500);
            return false;
        }

        var pergunta = document.querySelector("#pergunta");
        if ((dados.pergunta ?? '') === '') {
            toast.error('É necessário escrever uma pergunta');
            setTimeout(() => {
                pergunta.focus();
            }, 500);
            return false;
        }

        return true;
    };

    const salvar = () => {
        if (!validar()) {
            return false; //para codigo aqui
        }

        setIsSubmitting(true);

        api({
            method: 'post',
            action: '/eventoPergunta',
            data: dados
        })
            .then(function (response) {
                toast.success('Pergunta registrada com sucesso');
                //history.push("/eventoPerguntaRegistrada");
            })
            .catch(function (error) {
                toast.error('Erro: ' + (error.response?.data?.error ?? " ao carregar dados"));
            })
            .finally(() => {
                setIsSubmitting(false);
            });
    };


    useEffect(() => {
        buscar();
    },
        // eslint-disable-next-line
        []);

    return (
        <ErpClienteTemplate sidebar={false} imagemfundo={dadosEvento.imagemfundo?.link ?? null}>
            <main class="main-content position-relative" style={{ paddingTop: '20px' }}>
                <div class="card shadow-lg mx-4">
                    <div class="card-body p-3">
                        <div class="row gx-4">
                            <div class="col-auto">
                                <div class="avatar position-relative"
                                    style={{ height: '100px', width: '150px' }}>
                                    <img src={(dadosEvento.logomarca?.link ?? dadosEvento.cliente?.logomarca?.link ?? "")} alt="profile_image" class="w-100 border-radius-lg shadow-sm" />
                                </div>
                            </div>
                            <div class="col-auto my-auto">
                                <div class="h-100">
                                    <h5 class="mb-1">
                                        {(dadosEvento.nomersvp ?? dadosEvento.nome ?? "")}
                                    </h5>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="container-fluid py-4">
                    <div class="row">
                        <div class="col-md-8">
                            <div class="card">
                                <form className="form" onSubmit={(e) => { salvar(); e.preventDefault(); }}>
                                    <div class="card-header pb-0">
                                        <div class="d-flex align-items-center">
                                            <p class="mb-0">Faça sua pergunta</p>
                                        </div>
                                    </div>
                                    <div class="card-body">
                                        <div class="row">
                                            <div class="col-md-6">
                                                <div class="form-group">
                                                    <label for="example-text-input" class="form-control-label">Palestrante</label>
                                                    <ReactSelect
                                                        id="palestrante"
                                                        value={{ value: dados.palestrante, label: dados.nomePalestrante }}
                                                        onChange={e => setDados({ ...dados, ...{ "palestrante": e.value, "nomePalestrante": e.label } })}
                                                        loadOptions={(input) => {
                                                            return api({
                                                                method: 'get',
                                                                action: '/participanteSemLogin',
                                                                params: {
                                                                    nome: input,
                                                                    evento: id,
                                                                    tipoCadastro: ["participante", "palestrante"],
                                                                    tipoparticipante: 3,
                                                                    orderBy: { nome: 'asc' },
                                                                }
                                                            })
                                                                .then(response => {
                                                                    let options = response.data.data.map((registro) => {
                                                                        return {
                                                                            value: registro.id,
                                                                            label: registro.nome
                                                                        };
                                                                    });
                                                                    return options;
                                                                })
                                                                .catch(function (error) {
                                                                    //console.log(error);
                                                                });
                                                        }}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                        <div class="row">
                                            <div class="col-md-6">
                                                <div class="form-group">
                                                    <label for="example-text-input" class="form-control-label">Seu nome</label>
                                                    <input id="nome" class="form-control" type="text" placeholder='opcional'
                                                        value={dados.nome}
                                                        onChange={e => setDados({ ...dados, ...{ "nome": e.target.value } })}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                        <div class="row">
                                            <div class="col-md-12">
                                                <div class="form-group">
                                                    <label for="example-text-input" class="form-control-label">Sua pergunta</label>
                                                    <textarea id="pergunta" rows={7} class="form-control" placeholder='Escreva sua pergunta aqui...'
                                                        value={dados.pergunta}
                                                        onChange={e => setDados({ ...dados, ...{ "pergunta": e.target.value } })}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="card-footer pb-0">
                                        <div class="d-flex align-items-center">
                                            <button type="submit" class="btn btn-primary btn-sm ms-auto" disabled={isSubmitting}>
                                                <FaPaperPlane size={20} /> Enviar Pergunta
                                            </button>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </main>
        </ErpClienteTemplate >
    );
}