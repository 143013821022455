import React, { useState, useEffect, useRef } from 'react';
import { useHistory } from 'react-router-dom';
import ErpClienteTemplate from '../../../components/ErpClienteTemplate';
import NavBarCliente from '../../../components/NavBarCliente';
import ErpTemplate from '../../../components/ErpTemplate';
import { FaList, FaUpload, FaCalendarAlt, FaPen, FaPlus, FaSearch, FaThumbsUp, FaUsers, FaTable, FaThumbsDown } from 'react-icons/fa';
import { BsCardChecklist } from 'react-icons/bs';
import { GrMail } from "react-icons/gr";
import { ImCancelCircle } from 'react-icons/im';
import { GiOpenChest } from 'react-icons/gi';
import NavItem from '../../../components/NavItem';
import IconButton from '../../../components/IconButton';
import Alert from '../../../components/Alert';
import api from '../../../services/api';
import DataTable from 'react-data-table-component';
import moment from 'moment';
import Data from '../../../components/Data';
import { dataMysql } from '../../../util/util';
import Select from 'react-select';
import { useLocation } from 'react-router-dom';
import ReactSelect from '../../../components/ReactSelect';
import { toast } from 'react-toastify';
import FormButton from '../../../components/FormButton';

export default function PainelEventoPerguntaModeracao({ match }) {
  let { search, state } = useLocation();
  const id = match.params.id;
  const token = localStorage.getItem('token');
  const empresa = localStorage.getItem("empresa");
  const perfil = localStorage.getItem("perfil");
  const [filtro, setFiltro] = useState({});
  const [dadosEvento, setDadosEvento] = useState({});
  const [dados, setDados] = useState({});
  const [eventoPerguntas, setEventoPerguntas] = useState([]);
  const [loadingIds, setLoadingIds] = useState([]);
  const timerRef = useRef(null);

  const buscarEvento = () => {
    //busca evento
    api({
      method: 'get',
      action: '/eventoSemLogin/' + id,
    })
      .then(response => {
        setDadosEvento(response.data.data);
      }).catch(err => {
        //console.log(err)
      });
  };

  const buscarPerguntas = () => {
    api({
      method: 'get',
      action: '/eventoPergunta',
      params: {
        ...filtro,
        ...{ evento: id },
      }
    })
      .then(response => {
        setEventoPerguntas(response.data.data);
        setLoadingIds([]);
      }).catch(err => {
        //console.log(err)
      })
  };

  const atualizarSituacao = (id, novaSituacao, situacaoAnterior) => {
    setLoadingIds(prevLoadingIds => [...prevLoadingIds, id]);

    api({
      method: 'post',
      action: '/eventoPerguntaAprovacao/' + id,
      data: {
        situacao: novaSituacao,
      }
    })
      .then(function (response) {
      })
      .catch(function (error) {
        toast.error('Erro: ' + (error.response?.data?.error ?? " ao enviar dados"));
        setLoadingIds(prevLoadingIds => prevLoadingIds.filter(loadingId => loadingId !== id));
      });
  };

  useEffect(() => {
    buscarEvento();
    buscarPerguntas();
    timerRef.current = setInterval(() => {
      buscarPerguntas();
    }, 5000);

    // Limpa o intervalo quando o componente for desmontado
    return () => clearInterval(timerRef.current);
  },
    // eslint-disable-next-line
    []);

  return (
    <ErpClienteTemplate sidebar={false} imagemfundo={dadosEvento.imagemfundo?.link ?? null}>
      <main class="main-content position-relative" style={{ paddingTop: '20px' }}>
        <div class="card shadow-lg mx-4" style={{ marginBottom: '20px' }}>
          <div class="card-body p-3">
            <div class="row gx-4">
              <div class="col-auto">
                <div class="avatar position-relative"
                  style={{ height: '100px', width: '150px' }}>
                  <img src={(dadosEvento.logomarca?.link ?? dadosEvento.cliente?.logomarca?.link ?? "")} alt="profile_image" class="w-100 border-radius-lg shadow-sm" />
                </div>
              </div>
              <div class="col-auto my-auto">
                <div class="h-100">
                  <h5 class="mb-1">
                    {(dadosEvento.nomersvp ?? dadosEvento.nome ?? "")}
                  </h5>
                  <p class="mb-0 font-weight-bold text-sm">
                    Moderador
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="container-fluid">
          <div className="row">
            <div className="col-lg-12 mb-lg-0 mb-4">
              <div className="card">
                <div className="card-header pb-0">
                </div>
                <div className="card-body px-0 pt-0 pb-2">
                  <div className="table-responsive">
                    <DataTable
                      noContextMenu="true"
                      selectableRows={false}
                      loadOptions
                      noDataComponent="Nenhum registro"
                      paginationComponentOptions={{ rowsPerPageText: 'Linhas', rangeSeparatorText: 'de', selectAllRowsItem: true, selectAllRowsItemText: 'Todos' }}
                      pagination="true"
                      className="table mb-0"
                      columns={[
                        {
                          width: "120px",
                          cell: (row) => {
                            if (["nova", "reprovada"].includes(row.situacao ?? "nova")) {
                              return <IconButton
                                style={{
                                  marginBottom: "0px",
                                  padding: "10px 15px 10px 15px",
                                }}
                                small={true}
                                onClick={() => {
                                  const situacaoAnterior = row.situacao;
                                  atualizarSituacao(row.id, 'aprovada', situacaoAnterior);
                                }}
                                icon={loadingIds.includes(row.id) ? <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span> : <FaThumbsUp size={20} />}
                                color="default"
                                disabled={loadingIds.includes(row.id)}
                              >
                                Aprovar
                              </IconButton>;
                            } else {
                              return <IconButton
                                style={{
                                  marginBottom: "0px",
                                  padding: "10px 15px 10px 15px",
                                }}
                                small={true}
                                onClick={() => {
                                  const situacaoAnterior = row.situacao;
                                  atualizarSituacao(row.id, 'reprovada', situacaoAnterior);
                                }}
                                icon={loadingIds.includes(row.id) ? <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span> : <FaThumbsDown size={20} />}
                                color="default"
                                disabled={loadingIds.includes(row.id)}
                              >
                                Reprovar
                              </IconButton>;
                            }
                          }
                        },
                        {
                          name: 'Situação',
                          selector: row => row.situacao ?? "",
                          sortable: true,
                          width: "100px",
                          cell: (row) => {
                            var situacao = <span className="badge bg-primary">{row.situacao}</span>;
                            if (row.situacao === 'aprovada') {
                              situacao = <span className="badge bg-success">{row.situacao}</span>;
                            }
                            if (row.situacao === 'reprovada') {
                              situacao = <span className="badge bg-danger">{row.situacao}</span>;
                            }
                            if (row.situacao === 'respondida') {
                              situacao = <span className="badge bg-default">{row.situacao}</span>;
                            }
                            return situacao;
                          },
                        },
                        {
                          name: 'Pergunta',
                          cell: (row) => {
                            return <div style={{ minWidth: '200px' }}>{row.pergunta ?? ''}</div>;
                          },
                        },
                        {
                          name: 'Nome',
                          selector: row => row.nome ?? '',
                          sortable: true,
                          width: "150px",
                          cell: (row) => {
                            return row.nome ?? '';
                          },
                        },
                        {
                          name: 'Palestrante',
                          selector: row => row.palestrante?.nome ?? '',
                          sortable: true,
                          width: "150px",
                          cell: (row) => {
                            return row.palestrante?.nome ?? '';
                          },
                        },
                        {
                          name: 'Data',
                          selector: row => row.data ?? "",
                          sortable: true,
                          width: "150px",
                          cell: (row) => {
                            var data = row.data ? " " + moment(row.data).format("DD/MM/YYYY HH:mm") : "";
                            return data;
                          }
                        },
                        {
                          name: 'Número',
                          selector: row => row.codigo,
                          sortable: true,
                          width: "100px",
                          cell: (row) => {
                            return <div style={{ width: "100%", textAlign: 'center' }}>{row.codigo}</div>;
                          },
                        },
                      ]}
                      data={eventoPerguntas}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
    </ErpClienteTemplate>
  );
}